/* ---- Colors ---- */
$black: #000;
$white: #fff;
$red: #f00;

$gray-ligher: #e8e8e8;
$white-light: #e4e4e4;
$black-light: #404040;
$black-lighter: #707070;
$gray-de: #dedede;
$gray-light: #505050;
$dark-white: #f5f5f5;
$red-light: #f55353;
$light-gray: #9c9b9b;
$light-grayer: #eaeaea;
$gray-75: #757575;

/* ---- Text ---- */

// Font Family

$primary-font: "Open Sans", sans-serif;
$basic-font: Arial, Helvetica, sans-serif;

/* ---- Media Queries Breakpoints ---- */
$screen-xs: 575px;
$screen-sm: 767px;
$screen-md: 991px;
$screen-lg: 1199px;
