/* --------------------------------------------------
   Custom css
-------------------------------------------------- */

// progressbar css

.steps-form {
  .steps-row {
    .steps-step {
      display: flex;
      text-align: center;
      flex-wrap: wrap;

      a {
        padding: 18px 40px;
        color: $black;
        font-weight: 600;
        text-decoration: none;

        @media (max-width: 767px) {
          padding: 15px 20px;
        }
      }

      p {
        margin-top: 0.5rem;
      }

      button[disabled] {
        opacity: 1 !important;
        filter: alpha(opacity=100) !important;
      }
    }

    display: flex;
    background-color: $gray-ligher;
    color: $black;
  }

  .steps-step {
    a.active-main {
      background-color: var(--light-tone);
      color: $white;
    }
  }
}

.form-main {
    box-shadow: 0 3px 6px #0000001a;

    .setupcontent-inner {
        padding: 30px 40px;

        @media (max-width: 767px) {
            padding: 15px;
        }

        .stepcontent {
            max-width: 850px;

            .tooltip-inner {
                background-color: var(--light-tone);
            }

            .tooltip {
                margin-left: 15px;
            }

            .tooltip.fade.in {
                opacity: 1;
            }

            .tooltip.fade.right.in {
                opacity: 1;

                a.active-main {
                    color: $white;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent var(--light-tone) transparent transparent;
                }
            }

            @media (max-width: 767px) {
                .tooltip {
                    left: 15px !important;
                }

                .tooltip.fade.top.in {
                    opacity: 1;

                    &::after {
                        content: " ";
                        position: absolute;
                        bottom: -10px;
                        left: 45%;
                        margin-top: 0;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent var(--light-tone) transparent transparent;
                        transform: rotate(270deg);
                    }
                }
            }

            a[data-toggle="tooltip"] {
                color: #b9b9b9;
            }

            .payment-choice {
                label {
                    margin: 0;

                    &.error {
                        margin-left: -24px;
                        margin-top: 5px;
                    }
                }

                input {
                    width: 20px;
                    height: 20px;
                    margin: 0 0 0 10px;
                }

                .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: var(--light-tone);
                }

                .eft-check {
                    display: flex;
                    flex-wrap: wrap;

                    .error {
                        width: 100%;
                        order: 3;
                    }
                }
            }
        }

        .stepcontent2 {
       //   max-width: 850px;

          .tooltip-inner {
              background-color: var(--light-tone);
          }

          .tooltip {
              margin-left: 15px;
          }

          .tooltip.fade.in {
              opacity: 1;
          }

          .tooltip.fade.right.in {
              opacity: 1;

              a.active-main {
                  color: $white;
              }

              &::after {
                  content: " ";
                  position: absolute;
                  top: 50%;
                  right: 100%;
                  margin-top: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent var(--light-tone) transparent transparent;
              }
          }

          @media (max-width: 767px) {
              .tooltip {
                  left: 15px !important;
              }

              .tooltip.fade.top.in {
                  opacity: 1;

                  &::after {
                      content: " ";
                      position: absolute;
                      bottom: -10px;
                      left: 45%;
                      margin-top: 0;
                      border-width: 5px;
                      border-style: solid;
                      border-color: transparent var(--light-tone) transparent transparent;
                      transform: rotate(270deg);
                  }
              }
          }

          a[data-toggle="tooltip"] {
              color: #b9b9b9;
          }

          .payment-choice {
              label {
                  margin: 0;

                  &.error {
                      margin-left: -24px;
                      margin-top: 5px;
                  }
              }

              input {
                  width: 20px;
                  height: 20px;
                  margin: 0 0 0 10px;
              }

              .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
                  background-color: var(--light-tone);
              }

              .eft-check {
                  display: flex;
                  flex-wrap: wrap;

                  .error {
                      width: 100%;
                      order: 3;
                  }
              }
          }
      }
    }

    .button-footer {
        padding: 18px 40px 8px;
        background-color: $dark-white;

        @media (max-width: 767px) {
            padding: 15px 15px 7px;
        }

        button {
            margin-right: 40px;
            margin-bottom: 10px;

            @media (max-width: 767px) {
                margin-right: 10px;
            }

            &.saveBtn {
                &:hover {
                    background-color: var(--light-tone);
                    color: $white;
                }
            }
        }
    }
}

.body-main {
  padding: 40px 40px 60px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  .text-content {
    margin-bottom: 40px;
  }
}

.company-logo-content {
  display: flex;
  align-items: center;

  .logo-wrap {
    display: flex;
    height: 77px;
    margin-right: 20px;
    border: 2px solid $light-grayer;
    border-radius: 5px;
    flex-wrap: wrap;
    align-items: center;

    img {
      max-height: 70px;
      height: auto;
      width: 182px;
      object-fit: contain;
    }
  }

  .link-content-edit {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: var(--light-tone);
    font-weight: 600;

    i {
      margin-right: 6px;
    }
  }
}

//homepage css

.homepage-background {
  display: flex;
  height: calc(100vh - 68px);
  width: 100%;
  padding: 15px;
  background-image: url("../assets/images/homelightbg.png");
  background-repeat: no-repeat;
  background-size: 98% 100%;
  align-items: center;

  @media (max-width: 1199px) {
    background-size: cover;
  }

  .toast-component {
    max-width: 296px;

    @media (max-width: 767px) {
      max-width: 296px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
    }
  }
}

.bottom-footer-main {
  background-color: var(--dark-tone);
  padding: 13px;
  min-height: 68px;

  .content-wrap {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }

    .copyright-text {
      display: inline-block;
      width: 55%;
      color: $white;
      text-align: right;
      font-weight: bold;

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }

    .link-navigation {
      display: inline-block;
      width: 45%;
      text-align: right;

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }

      a {
        color: $white;
        font-weight: bold;
        display: inline-block;
      }
    }
  }
}

.pageNumbers {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.head-content-page {
  margin-bottom: 50px;
}

.page-main-wrap {
  .border-line-first {
    padding-left: 24px;
  }

  .line-border-form {
    .border-line {
      li {
        border-bottom: 1px solid $black;
        list-style-type: none;
        display: inline-block;
        width: 50%;
        padding: 10px 0;
      }
    }
  }
}

.homepage-main {
  display: flex;
  max-width: 960px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 17px;
  background-color: $white;
  box-shadow: 3px 9px 28px #0000000d;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    display: inline-block;
    width: 100%;
    background-color: transparent;
  }

  .left-section {
    @media (max-width: 767px) {
      width: 100% !important;
      text-align: center;
    }

    img {
      display: inline-block;
      width: 100%;

      @media (max-width: 767px) {
        max-width: 300px;
      }
    }
  }

  .right-section {
    display: flex;
    padding-left: 80px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    .homebtnwrap {
      img {
        max-height: 50px;
      }
    }

    .title-main {
      margin-bottom: 40px;
    }

    @media (max-width: 1199px) {
      padding-left: 40px;
    }

    @media (max-width: 767px) {
      width: 100% !important;
      padding-left: 0;
      text-align: center;
    }
  }
}

.homepage-main {
  .left-section,
  .right-section {
    width: 50%;
  }
}

// quickbook page
.quickbook-page {
  padding: 15px;

  .text-content {
    margin-bottom: 56px;

    .title-main {
      margin-bottom: 13px;
    }
  }

  .quickbook-btnwrap {
    .btn-left-icon {
      padding: 7px 18px;
    }
  }
}

.page-mainwrap {
  display: flex;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;

  &.with-header {
    height: calc(100vh - 65px);

    @media (max-width: 767px) {
      height: calc(100vh - 117px);
    }
  }
}

.invoice-steps {
  padding: 0 40px 60px;
  border-top: 6px solid var(--light-tone);
  background-color: $white;

  @media (max-width: 767px) {
    padding: 0 15px 30px;
  }

  .company-logo-main {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid $light-grayer;

    img {
      max-height: 106px;
    }

    .company-main-title {
      font-weight: bold;
      color: $black;
    }
  }

  .title-wrap {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .invoice-in-wrap {
    border-top: 12px solid var(--light-tone);
    box-shadow: 0 3px 6px #0000001a;

    .invoice-group {
      max-width: 780px;
      padding: 40px 40px 60px;

      @media (max-width: 767px) {
        max-width: 100%;
        padding: 20px 10px 40px;
      }
    }

    .form-groupwrap {
      .form-group {
        &:last-child {
          margin-bottom: 30px;
        }
      }

      label.error, .label-error {
        color: $red;
      }
    }

    .pay-cardsection,
    .cardsection {
      img {
        max-width: 20px;
        margin-right: 14px;
      }

      .pay-title {
        display: flex;
        padding: 15px 20px;
        border-radius: 5px 5px 0 0;
        background-color: var(--light-tone);
        color: $white;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;

        @media (max-width: 767px) {
          padding: 15px 10px;
        }

        .pay-title-wrap {
          display: flex;
          align-items: center;

          .img-wrap {
            display: flex;
            align-items: center;
          }
        }

        .pat-bambora-logo {
          margin-left: 10px;
          img {
            max-width: 60px;
            margin-right: 0;
          }
        }
      }

      .pay-card-inputs,
      .card-inputs {
        padding: 20px;
        border: 2px solid $light-grayer;
        border-top: none;
        border-radius: 5px;

        @media (max-width: 767px) {
          padding: 10px;
        }
      }
    }

    .button-footer {
      padding: 18px 40px;
      background-color: $dark-white;

      @media (max-width: 767px) {
        padding: 18px 10px;
      }
    }
  }
}

// combine input css
.combine-input {
  display: inline-block;
  width: 100%;
  vertical-align: top;

  .form-group {
    float: left;
    display: inline-block;
    width: calc(50% - 9px);
    margin-right: 18px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-messagewrap {
  display: flex;
  margin: 40px 40px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid $light-grayer;
  align-items: center;

  @media (max-width: 767px) {
    margin: 0;
    padding: 15px;
    flex-direction: column;
  }

  .message-image-wrap {
    margin-right: 30px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    img {
      max-width: 78px;

      @media (max-width: 767px) {
        max-width: 40px;
      }
    }
  }

  .message-content-wrap {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 0;
  }
}

.payment-summary,
.summary {
  .pay-card-inputs,
  .card-inputs {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    ul {
      margin-bottom: 0;
      padding-left: 0;

      li {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 2px solid $light-grayer;
        list-style-type: none;

        &:last-child {
          border-bottom: none;
        }

        .title-content,
        .value-content {
          display: inline-block;
          color: $black-light;
          font-size: 14px;
        }

        .value-content {
          color: $black;
          font-weight: bold;
        }
      }
    }
  }
}

.company-profile {
  margin-bottom: 40px;
  box-shadow: 0 1px 15px #0000001a;

  img {
    max-height: 107px;
    padding: 10px 40px;
  }
}

.invoice-detail {
  .invoice-group {
    max-width: 100% !important;
    padding-top: 0 !important;

    .invoice-details-list {
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
      padding-top: 35px;
      padding-bottom: 20px;
      padding-left: 0;
      border-bottom: 2px solid $light-grayer;

      li {
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        padding-right: 50px;
        color: $black;
        font-size: 20px;
        font-weight: bold;
        list-style-type: none;

        @media (max-width: 991px) {
          width: 100%;
          padding-left: 30px;
        }

        &::before {
          position: absolute;
          top: 11px;
          left: -32px;
          display: inline-block;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $gray-de;
          content: "";

          @media (max-width: 991px) {
            top: 10px;
            left: 5px;
          }
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        &:first-child {
          &::before {
            display: none;

            @media (max-width: 991px) {
              display: block;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

        .true {
          color: var(--light-tone);
        }

        .false {
          color: $red-light;
        }
      }
    }
  }
}

// preview page code
.preview-page {
  .invoice-group {
    max-width: 100% !important;

    .card-inputs {
      .title-content {
        min-width: 200px;

        @media (max-width: 767px) {
          min-width: auto;
          width: 100%;
        }
      }

      .value-content {
        word-break: break-all;

        &.company-logo-img {
          img {
            max-height: 70px;
            max-width: 100%;
          }
        }
      }
    }
  }
}

// custom li structure code (invoice detail)
.li-structure {
  ul {
    max-width: 271px;
    margin-left: auto;
    padding-left: 0;
    border-top: none;
    border-bottom: none;

    li {
      padding: 20px;
      border-right: 2px solid $light-grayer;
      border-bottom: 2px solid $light-grayer;
      border-left: 2px solid $light-grayer;
      list-style-type: none;

      @media (max-width: 767px) {
        padding: 10px;
      }

      .title-content,
      .value-content {
        display: inline-block;
        color: $black-light;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
      }

      .value-content {
        color: $black;
        font-weight: bold;
      }

      &.btn-fullwidth {
        padding: 0;
        border: none;

        a {
          display: flex;
          height: 48px;
          width: 100%;
          border-radius: 0;
          align-items: center;
          justify-content: center;
        }
      }

      &.balance-output {
        border-color: $dark-white;
        background-color: $dark-white;
      }
    }
  }
}

.calculation-table {
  @media (max-width: 767px) {
    margin-top: 20px;

    .li-structure {
      ul {
        max-width: 100%;

        li {
          &:first-child {
            border-top: 2px solid $light-grayer;
          }

          .title-content {
            width: 120px;
          }
        }
      }
    }
  }
}

// css for invoice detail page
.table-wrap {
  width: 100%;
  overflow-y: auto;

  .invoice-detail-table-main {
    width: 100%;

    .invoice-detail-table {
      th {
        padding: 35px 10px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: 0.36px;

        &:nth-child(4) {
          width: 136px;
        }

        &:nth-child(5) {
          width: 136px;
        }

        @media (max-width: 767px) {
          min-width: 150px;
          padding: 10px;
        }
      }

      tr {
        border-bottom: 2px solid $light-grayer;
      }

      td {
        padding: 20px 15px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        letter-spacing: 0.32px;

        @media (max-width: 767px) {
          min-width: 150px;
          padding: 10px;
        }
      }
    }
  }
}

.mobile-view-table {
  margin-top: 20px;

  @media (min-width: 768px) {
    display: none;
  }

  &.list {
    @media (max-width: 991px) {
      display: inline-block;
      width: 100%;
    }
  }

  .table-card {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .li-structure {
      ul {
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;
        border: 2px solid $light-grayer;

        li {
          padding: 10px;
          border-right: none;
          border-left: none;

          .title-content {
            width: 120px;
          }
        }
      }
    }
  }
}

.desktop-view {
  @media (max-width: 767px) {
    display: none;
  }
}

.invoice-list-head {
  margin-bottom: 40px;
  padding: 30px 30px 20px;
  background-color: var(--light-tone);
  box-shadow: 0 3px 6px #0000001a;

  .invoice-list-head-btn {
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-right: 25px;
      @media (max-width: 480px) {
        margin-bottom: 20px;
      }
    }
  }

  .combine-input {
    .form-group {
      width: calc(50% - 15px);
      margin-right: 30px;

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.invoice-list {
  .invoice-in-wrap {
    .invoice-group {
      max-width: 100%;
      padding-top: 0;

      .invoice-detail-table {
        thead {
          border-bottom: 2px solid $light-grayer;
        }

        tr {
          border-bottom: none;

          td {
            padding: 15px 10px;

            .btn-outline {
              height: 37px;
              min-width: 122px;

              i {
                margin-right: 10px;
                font-size: 20px;
              }

              &:hover {
                color: var(--dark-tone);
              }
            }

            .btn-width-auto {
              min-width: 67px;
            }
          }
        }

        th {
          &:nth-child(4) {
            width: auto;
          }

          &:nth-child(5) {
            width: auto;
          }
        }
      }

      .mobile-view-table {
        &.list {
          @media (min-width: 992px) {
            display: none;
          }
        }

        @media (min-width: 992px) {
          display: none;
        }

        .btn-outline {
          &:hover {
            color: var(--dark-tone);
          }
        }
      }

      .desktop-view-list {
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
}

.iframe-input-pay {
  padding: 40px 20px 20px !important;

  input,
  label,
  iframe {
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
  }

  

  label.error, .label-error {
    color: $red;
  }
}

#invoiceList_wrapper {
  margin: 30px 0;

  @media (max-width: 767px) {
    .row {
      flex-direction: column;

      #invoiceList_paginate {
        display: flex;
        justify-content: flex-start;
        margin: 0;
      }
    }
  }

  #invoiceList_length {
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    label {
      display: flex;
      align-items: center;

      .custom-select {
        height: 41px;
        width: 80px;
        margin-left: 5px;
        margin-right: 10px;
      }
    }
  }
}

#invoiceList_filter,
#billList_filter,
#customerList_filter {
  label {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }

    input {
      margin-left: 10px;
      margin-right: 5px;
      height: 41px;
    }
  }
}

#invoiceList_info {
  font-weight: 600;
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 15px;
}

#invoiceList_paginate,
#billList_paginate {
  margin-top: 30px;
  margin-bottom: 15px;

  .pagination {
    justify-content: flex-end;
    padding: 15px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      padding: 0 15px;
    }

    li {
      a {
        color: var(--dark-tone);
        font-weight: 600;
      }
    }

    .paginate_button {
      .page-link {
        color: var(--dark-tone);
      }

      &.active {
        .page-link {
          background-color: var(--dark-tone);
          border-color: var(--dark-tone);
          color: $white;
        }
      }
    }
  }
}

.dataTables_processing {
  text-align: center;

  #loaders {
    img {
      max-height: 105px;
    }
  }
}

// css for payment form start

#card-number,
#card-cvv,
#card-expiry {
  background-color: $white;
  display: block;
  border-radius: 2px;
  border: 1px solid $light-grayer;
  // border: 1px solid rgba(200, 200, 200, 1);
  // padding: 14px 60px 13px 20px;
  margin: auto;
  transition: all 100ms ease-out;

  &:focus {
    border-color: $light-grayer;
    box-shadow: 0 0 0 0.2rem $light-grayer;
  }
}

.step-content-2,
.bill-pay-section {
  

  .label-error {
    margin-bottom: 0;

    &:empty {
      display: none;
    }
  }

  .error {
    margin-bottom: 0;
  }

  .iframe-input-pay .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    //   background-color: #FFF;
    //   display: block;
    //   border-radius: 2px;
    //   border: 1px solid #c8c8c8;
    //   padding: 14px 60px 13px 20px;
    //   margin: auto;
    //   transition: all 100ms ease-out;
    //   min-height: 53px;
    //   font-size: 13px;

    &:focus {
      box-shadow: none;
      border: 1px solid #c8c8c8;
    }

    &::placeholder {
      color: #9c9b9b;
      font-weight: 400;
      font-size: 13.3333px;
      font-family: arial;
    }

    &:-ms-input-placeholder {
      color: #9c9b9b;
      font-weight: 400;
      font-size: 13.3333px;
      font-family: arial;
    }

    &::-ms-input-placeholder {
      color: #9c9b9b;
      font-weight: 400;
      font-size: 13.3333px;
      font-family: arial;
    }
  }

  .pay-card-inputs.iframe-input-pay {
    padding-bottom: 20px !important;
  }
}

/* card images are added to card number */
#card-number {
  background-image: none;
  background-origin: content-box;
  background-position: calc(100% + 40px) center;
  background-repeat: no-repeat;
  background-size: contain;
}

button#pay-button.disabled {
  background-color: #585858;
}

/* feedback is displayed after tokenization */
#feedback {
  position: relative;
  left: 15px;
  display: inline-block;
  background-color: transparent;
  border: 0px solid rgba(200, 200, 200, 1);
  border-radius: 4px;
  transition: all 100ms ease-out;
  padding: 11px;
}

#feedback.error {
  color: $red;
  border: 1px solid;
}

#feedback.success {
  color: var(--dark-tone);
  border: 1px solid;
}

/*// css for payment form end*/

// admin login page

.admin-login {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;

  .admin-inner-wrap {
    display: inline-block;
    width: 100%;
    max-width: 800px;

    .invoice-steps {
      border-top: none;
      padding: 0;
    }

    .form-main {
      padding: 40px 30px;

      @media (max-width: 767px) {
        padding: 40px 0;
      }
    }

    .main-title {
      margin-bottom: 30px;
    }
  }
}

// data table section
.dataTables_wrapper {
  .dataTables_length,
  .dataTables_filter {
    margin-top: 20px;
    /*display: inline-block;*/
    display: inline-block;
    width: 100%;

    label {
      display: inline-flex;
      align-items: center;
    }
  }

  .dataTables_length {
    select.form-control {
      margin-left: 10px;
      margin-right: 10px;
      height: auto;
      min-width: 70px;
    }
  }

  .dataTables_filter {
    text-align: right;

    @media (max-width: 767px) {
      text-align: left;
    }

    label {
      width: 100%;
      max-width: 500px;

      .form-control {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .row {
    &:last-child {
      background-color: $dark-white;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;

      .dataTables_info {
        font-weight: 600;
        letter-spacing: 0.32px;
      }

      .dataTables_paginate {
        justify-content: flex-end;

        @media (max-width: 767px) {
          justify-content: flex-start;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .pagination {
          justify-content: flex-end;
          padding: 15px;
          margin-bottom: 0;

          @media (max-width: 767px) {
            padding: 0 15px;
            justify-content: flex-start;
          }

          li {
            a {
              color: var(--dark-tone);
              font-weight: 600;
              background-color: transparent;
              border-color: transparent;
            }
          }

          .paginate_button {
            .page-link {
              color: $black-light;
            }

            &.active {
              .page-link {
                color: var(--light-tone);
              }
            }
          }

          .last {
            margin-left: 54px;

            a {
              @extend .btn;
              width: auto;
              color: $white !important;
              min-width: auto;
            }
          }
        }
      }
    }
  }
}

.failed-transaction-section {
  .combine-input {
    display: flex;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .form-group {
      @media (max-width: 991px) {
        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }
  }
}

.iframe-input-pay {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  textarea,
  select,
  .form-control {
    color: $black;
    border: 1px solid $light-grayer !important;
    font: 400 13.3333px Arial;
    color: $black;
    background-color: $white !important;

    &::placeholder {
      color: $gray-75;
    }
  }

  label.label-error {
    margin-bottom: 0;
  }

  label.label-error:empty {
    display: none;
  }

  .saved-info-section {
    color: $gray-75;
    display: flex;
    align-items: center;
    font: 400 13.3333px Arial;
    margin-bottom: 20px;

    input {
      margin-right: 5px;
    }
  }
}

// modal custom bootstrap css
.modal {
  &.fade {
    padding: 15px;
    opacity: 0;
    transition: opacity 0.15s linear;
    background-color: rgba(0, 0, 0, 0.7);
    &.in {
      opacity: 1;
    }
  }
}

.modal {
  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.fade {
    .modal-dialog {
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
    }
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;

  @media (min-width: 768px) {
    width: 600px;
    margin: 30px auto;
    position: relative;
  }

  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-content {
    position: relative;
    background-color: $white;
    background-clip: padding-box;
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    outline: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;

    @media (min-width: 768px) {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .modal-header {
      padding: 15px;
      border-bottom: 1px solid var(--dark-tone);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid $gray-ligher;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      background-color: var(--light-tone);
      color: $white;

      .modal-title {
        font-weight: bold;
      }

      .close {
        cursor: pointer;
        color: $white;
        opacity: 1;
      }
    }

    .modal-body {
      .content-modal-body {
        display: flex;
        margin-bottom: 10px;
      }
      label {
        font-weight: bold;
        min-width: 160px;
        margin-bottom: 0;
        @media (max-width: 575px) {
          min-width: 140px;
        }
      }

      label,
      span {
        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }
}

.modal.fade .modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// modal custom bootstrap css end

#billList_wrapper {
  .selected {
    background-color: rgba(108, 184, 96, 0.3); 
  }
}

.center_label {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.dt-body-right, .dt-head-right {
  text-align: right;
}

.dt-body-center, .dt-head-center{
  text-align: center;
}

.dt-body-left, .dt-head-left{
  text-align: left;
}

.remove-border-top {
  border-top: none !important;
}

.loading-spinner {
	display: none;
}

.loading-spinner.active {
	display: inline-block; 
}

.btn-primary.disabled,
.btn-primary:disabled
 {
  color: #fff;
  background-color: #6cb860;
  border-color: #ffffff;
}

// Simulate placeholder functionality on input date element
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}


// Clover

input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel], textarea, select, .form-control {
  padding: 6px 12px !important;
}
