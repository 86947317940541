//@import '~@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome
body {
  font-size: 16px;
  font-family: $primary-font;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  color: $black-light;
}

.fa-spinner {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
