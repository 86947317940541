/* --------------------------------------------------
  Buttons styling
-------------------------------------------------- */

.btn {
  display: inline-flex;
  height: 41px;
  min-width: 141px;
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--light-tone);
  background-image: none;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
 /* text-transform: capitalize;*/ 
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--dark-tone);
    background-color: var(--dark-tone);
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-primary {
    padding: 9px;
  }

  &.btn-arrow-right {
    padding: 9px 15px;
    flex-wrap: wrap;

    img {
      max-width: 19px;
      margin-left: 15px;
    }
  }

  &.btn-arrow-left {
    padding: 9px 15px;

    img {
      max-width: 19px;
      margin-right: 15px;
    }
  }

  &.btn-width-auto {
    height: auto;
    min-width: auto;
  }

  &.btn-outline {
    border: 2px solid var(--light-tone);
    background-color: transparent;
    color: var(--light-tone);
  }

  &.btn-blank {
    min-width: auto;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
    color: var(--light-tone);
  }

  &.btn-dark-tone {
    background-color: var(--dark-tone);
    color: $white;
  }

  &.btn-bg-white {
    background-color: $white;
    color: var(--light-tone);
    &:hover {
      color: var(--dark-tone);
    }
  }

  &.btn-color-white {
    border-color: $white;
    background-color: var(--light-tone);
    color: $white;

    &:hover {
      background-color: var(--dark-tone);
    }
  }
}

.btn-left-icon {
  padding: 7px 35px 7px 39px;
  border-radius: 5px;
  color: $white;
  font-weight: 600;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  img {
    height: 27px;
    width: 27px;
    margin-right: 13px;
  }

  i {
    margin-right: 8px;
  }
}

#billList_wrapper {
  .collection-btn {
    border: 2px solid #393a3d;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 36px;

    min-height: 36px;
    min-width: 100px;

    
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 600;
  }

  #selectAll, #billBulkPayment {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--light-tone);
    background-image: none;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;
  }

  #selectNone {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border-radius: 5px;
    background-color: var(--light-tone);
    background-image: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;

    border: 2px solid var(--light-tone);
    background-color: $white;
    color: var(--light-tone);
  }
}

#invoiceList_wrapper {
  .collection-btn {
    border: 2px solid #393a3d;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 36px;

    min-height: 36px;
    min-width: 100px;

    
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 600;
  }

  #invoiceAutoPayment {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--light-tone);
    background-image: none;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;

    &.btn-disabled {
        background-color: #ccc; // Change to your desired color
        color: #666; // Change to your desired color
        cursor: not-allowed;
    } 
    
  }  
}

#customerList_wrapper {
  .collection-btn {
    border: 2px solid #393a3d;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 36px;

    min-height: 36px;
    min-width: 100px;

    
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 600;
  }

  #addCustomer {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border: none;
    border-radius: 5px;
    background-color: var(--light-tone);
    background-image: none;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;    
  }  
}